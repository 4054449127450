import { Box, Chip, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";

export function StatusFilter({ onFiltered, initialStatus }) {
  const [statuses, setStatuses] = useState([
    { id: 1, name: "abertas" },
    { id: 2, name: "resolvidos" }
  ]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);

  useEffect(() => {
    setSelectedStatuses([]);
    if (
      Array.isArray(initialStatus) &&
      Array.isArray(statuses) &&
      statuses.length > 0
    ) {
      onChange(initialStatus);
    }
  }, [initialStatus, statuses]);

  const onChange = (value) => {
    setSelectedStatuses(value);
    onFiltered(value);
  };

  return (
    <Box style={{ padding: "0px 10px 10px" }}>
      <Autocomplete
        multiple
        size="small"
        options={statuses}
        value={selectedStatuses}
        onChange={(e, v) => onChange(v)}
        getOptionLabel={(option) => option.name}
        getOptionSelected={(option, value) => option.id === value.id}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              style={{
                backgroundColor: option.name === "abertas" ? "#f44336" : "#4caf50",
                color: "white",
                textShadow: "1px 1px 1px #000",
              }}
              label={option.name}
              {...getTagProps({ index })}
              size="small"
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder="Filtro por Status"
          />
        )}
      />
    </Box>
  );
}
